var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
function PagesSectionNodes(el) {
  var $el = $(el);
  $el.find('.page-section-node').css('cursor','pointer');
  $el.find('.page-section-node').one('click',function(event) {
    console.log('clicky');
    if(event.target.nodeName != 'a') {
      window.location.href = $(this).find('a.page-section-link').attr('href');
    }
  });
}
module.exports = PagesSectionNodes;
