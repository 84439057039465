var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

// HACK: A series of hacks to bend the quiz module to our will. Ultimately when the full site moves over to new design, this should be edited in actual module

function QuizQuestions(el) {
  var $el = $(el),
      $quiz_progress = $('#quiz_progress'),
      quiz_progress_html = $quiz_progress.html() || '',
      $quiz_form = $el.find('.answering-form');

  // Set new html to element
  $quiz_progress.html(quiz_progress_html);

  // Move Title next to progress
  $el.find('.quiz-question-body').appendTo($quiz_progress);

  // form validation ensure an answer is checked
  $quiz_form.on('submit', function (e) {
    if ($el.find('input:checked').length === 0) {
      alert('Please select your answer')
      e.preventDefault();
    }
  })

  // Move Dr recommendation to speech bubble
  $el.find('.quiz-results__summary p:first').appendTo('.quiz-results__convo-dr');

}

module.exports = QuizQuestions;
