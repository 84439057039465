var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var throttle = require('lodash.throttle');

function Header(el) {
  var $el = $(el),
      $document = $(document),
      $body = $('body'),
      enableFixedHeader = $el.data('fixed-header'),
      $headerSticky = $('.header-sticky-ad'),
      $headerStickyWrap = $('.header-sticky-ad-wrap');

  if( enableFixedHeader !== undefined ) {
    adjustHeader();
    $document.on('scroll', throttle(adjustHeader, 200));
  }

  function adjustHeader() {

    // take up same space when ad moves to Fixed
    // $headerStickyWrap.height($headerSticky.outerHeight());
    // $('.header-wrap-fixed').height($el.outerHeight()-4);

    // Fix everything if past top of scroll
    if ($document.scrollTop() > 0) {
      $body.addClass('fixed-header');
    } else {
      $body.removeClass('fixed-header');
    }

    // Hide ad after 500px of scroll
    if ($document.scrollTop() > 500) {
      $headerSticky.addClass('hide');
    // else if scrolled back to top
    } else if ($document.scrollTop() === 0) {
      $headerSticky.removeClass('hide');
      // reset height so it isn't 0.
      $headerStickyWrap.height('auto');
    }

    // offset from pink header
    $headerSticky.css('top', $el.outerHeight());
  }

  $el.find('.header__search').on('click', function (e) {
    e.preventDefault();
    $('.wrapper--search').toggleClass('expanded');
    $('.header-wrap-fixed').toggleClass('search-expanded');
  });

  $('.wrapper--search .search__close').on('click', function (e) {
    e.preventDefault();
    $('.wrapper--search').toggleClass('expanded');
    $('.header-wrap-fixed').toggleClass('search-expanded');
  });

  $('[data-button-scroll]').on('click', function(e) {
    var
      target = $(this).attr('href'),
      stickyBannerHeight = $('.header-wrap-fixed').height();

    if( target.match(/(^http:\/\/)|(^www)/) == null ) {
      e.preventDefault();
      $('html, body').stop().animate({
        'scrollTop': $(target).offset().top - $el.outerHeight() - stickyBannerHeight - 20
      }, 400);
    }
  });

}

module.exports = Header;
