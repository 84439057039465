var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

function QuizResults(el) {
  var $el = $(el);

  // Move Dr recommendation to speech bubble
  $el.find('.quiz-results__summary p:first').appendTo('.quiz-results__convo-dr');

  // If "mobile"
  if ($(window).width() <= 480) {
    $('#related-content').insertAfter($el.find('.quiz-results__summary p:first'));
  }

  $('#block-bmed-ads-bmed-ad').insertAfter('.quiz-results__convo-dr');

}

module.exports = QuizResults;
