var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var initializeModules = require( "./lib/init-modules.js" );
var herostate = 'mobile';

// Hero swaps
$(window).resize(function() {
  var $hero = $('.hero-image');
  if ($hero.length) {
    var $width = $(window).width();
    if ($width < 600 && herostate!=='mobile') {
      var $mobileimg = $hero.attr('data-mobile-bg');
      herostate = 'mobile';
      $hero.css('background-image',"url("+$mobileimg+")");
    } else if($width >= 600 && herostate!=='desktop') {
      var $desktopimg = $hero.attr('data-desktop-bg');
      herostate = 'desktop';
      $hero.css('background-image',"url("+$desktopimg+")");
    }
  }
});

$(function() {
	initializeModules();
  gotoAnchor(window.location.hash);
  if($('.view-id-keyword_glossary').length){
    var keywordTotal=0;
    $('.view-id-keyword_glossary .wrap-parenthesis .field-content').each(function(){
      keywordTotal += parseInt($(this).text());
    });
    $('.view-id-keyword_glossary .view-content').append('<br/><div class="keyword-count"><b>Total: </b>'+keywordTotal+'</div>');
  }
  $('#content table').each(function() {
    if ($(this).width()>$('#content').width() && !$(this).hasClass('fc-header') && !$(this).hasClass('sticky-header')){
      $(this).wrap('<div class="table-responsive">');
      var $controls = '<div class="table-controls"><span class="instruction">';
      $controls+='Scroll/Swipe to see more</span><span class="fa fa-arrows-h"></span></div>';
      $(this).before($controls);
    }
  });
  $('a[href^="#"]').click(function(e) {
    e.preventDefault();
    gotoAnchor($(this).attr('href'));
    window.location.hash = $(this).attr('href');
  });
  gotoAnchor(window.location.hash);
  $(window).resize();

  // Listen for remodal close
  $(document).on('closed', '.remodal', function (e) {
    window.dataLayer = window.dataLayer || [];
    // Send custom GTM event
    window.dataLayer.push({
      'event': 'popupClose'
    });
  });

});

function gotoAnchor($anchor) {
  if (typeof $anchor !== "undefined" && $anchor) {
    var $anchorElement = $($anchor);
    // Use the height of the fixed menu and some additional padding to offset the anchor
    var fixedElementHeight = $('.wrapper--header').eq(0).height()+10;
    // Scroll to the anchor with the menu heigh as an offset
    $('html, body').stop().animate({
       scrollTop: $anchorElement.offset().top - fixedElementHeight
    }, 200);
  }
}

$(window).on('load',function() {
  gotoAnchor(window.location.hash);
  $(window).resize();
});

Drupal.behaviors.campaignion_ux_webform_ajax_scroll = {};
Drupal.behaviors.campaignion_ux_webform_ajax_scroll.attach = function(context) {
  // Scroll to top of the form + padding if we are below or more than the
  // toleratedOffset above it.
  var padding = 12;
  var toleratedOffset = 50;
  $('.next-btn-copy').on('click',function(e) {
    e.preventDefault();
    $(this).closest('form').find('.form-actions input.webform-next').click();
  });


  if ($(context).is('[id^=webform-ajax-wrapper]')) {
    // This is the result of an AJAX submit.
    var scrolltop = $('#ads-wrapper').offset().top;
    var diff = scrolltop - $(document).scrollTop();
    if (diff < 0 || diff > toleratedOffset) {
      $('body, html').animate({ scrollTop: (scrolltop)}, 'slow');
    }
  }
};

Drupal.behaviors.mcpopup = {};
Drupal.behaviors.mcpopup.attach = function(context) {
  // var $mcform = $('#block-mailchimp-signup-obie-popup').detach();
  // $('#remodal-content').append($mcform);
  var inst = $('[data-remodal-id=modal]').remodal();

  //New date
  var d = new Date();
  d.setTime(d.getTime() + (7*24*60*60*1000));
  var expires = "; expires="+d.toUTCString();

  //Global path
  var path = "; path=/";
  var date_path = expires + path;

  // Set the cookie
  if (!checkCookie('STYXKEY-alert')) {
    document.cookie = 'STYXKEY-alert=' + '[MCPOPUP]' + date_path;
    setTimeout(function () {
      inst.open();
    }, 7000);
  }

};
// $(#mailchimp-newsletter-0942d27a78-mergefields)


// function for tracking questionnaire progress in GTM with custom event and variable
Drupal.behaviors.trackQuestionnaireProgress = {};
Drupal.behaviors.trackQuestionnaireProgress.attach = function(context) {
  if (!$('article.node-webform div.messages.error').length && $('.webform-progressbar-number:not(.processed)').length) {
    var progress = $('.webform-progressbar-number:not(.processed)').eq(0).text();
    // dump last step value to data attribute
    if ($('article.node-webform').attr('data-last-step') != progress) {
      $('article.node-webform').attr('data-last-step',progress);
      $('.webform-progressbar-number').addClass('processed');
      dataLayer.push({'event': 'questionProgress', 'step': progress});
    }
  }
};

// Cookie stuff
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i=0; i<ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}

function checkCookie(cname) {
  var alertCookie = getCookie(cname);
  if (alertCookie != "") {
    return true;
  } else {
    return false;
  }
}
