var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

function Slideout(el) {
  var $el = $(el);
  $el.find('.nav-trigger').on('click', function() {
    $('html').toggleClass('js-slideout-active');
  });

  $(window).on('scroll',function(){
    if($(window).scrollTop()==0){
      setTimeout(function () {
      $el
          .find('.nav-trigger')
          // get the offset of the header and add that to the top of the nav-tigger
          .css('top', $('.header').offset().top + 25 + 'px'); // 25px is already the "top" of this element
      }, 500);
    }
  });

  $(window).on('load',function(){
    $el.find('.nav-trigger')
          // get the offset of the header and add that to the top of the nav-tigger
        .css('top', $('.header').offset().top + 25 + 'px');
  });
}

var $timeline = $('.slideout .wrapper--timeline').detach();
$('.slideout .header-menu--main').after($timeline);

var $menu =$('.slideout__scroll');

$(document).mouseup(function (e) {
   if (!$menu.is(e.target) // if the target of the click isn't the container...
   && $menu.has(e.target).length === 0) // ... nor a descendant of the container
   {
     $('html').removeClass('js-slideout-active');
  }
 });

module.exports = Slideout;
